import React, { useState } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useMediaQuery } from 'react-responsive'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import Alert from '../../../components/Alert/Alert'
import PaginationNav from '../../../components/PaginationNav/PaginationNav'

import { orders } from '../../../utils/orders'
import { getPaginatedResults, orderByDate } from '../../../utils/sorting'
import { FaExternalLinkAlt } from 'react-icons/fa'

const ChargesPage = () => {
  const intl = useIntl()
  const isScreenSm = useMediaQuery({ maxWidth: 1200 })
  const [currentPage, setPage] = useState(1)

  return (
    <Layout
      header={
        <span className='bold'>
          {/* <FormattedMessage id='nav.notices' /> */}
          Recent Registrar's Actions
        </span>
      }
      breadcrumb={{
        text: <FormattedMessage id='nav.regActivites' />,
        path: '/licensing-compliance/regulatory-activites-enforcement/'
      }}
    >
      <SEO
        description={intl.formatMessage({
          id: 'metaTags.regActivitiesDescription'
        })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.notices' })}
      />
      <Container>
        <Row>
          <Col>
            {intl.locale === 'fr' && (
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            )}
            {isScreenSm ? (
              <>
                {getPaginatedResults(
                  orderByDate(orders, 'date'),
                  currentPage
                ).map((order, i) => (
                  <div className='tile' key={i}>
                    <p>
                      {order.internal ? (
                        <a
                          href={`../../../../${order.link}`}
                          className='linkHeader'
                        >
                          <FaExternalLinkAlt />
                          {order.vb} - PDF
                        </a>
                      ) : (
                        <a href={order.link} className='linkHeader'>
                          <FaExternalLinkAlt />
                          {order.vb}
                        </a>
                      )}
                      <br />
                      <b>Notice/Order Issued:</b> {order.order}
                      {order.note && (
                        <b>
                          <br />
                          {order.note}
                        </b>
                      )}
                      <br />
                      <b>Date Issued:</b> {order.date}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Vendor/Builder</th>
                    <th>Notice/Order Issued</th>
                    <th>Date Issued</th>
                  </tr>
                </thead>
                <tbody>
                  {getPaginatedResults(
                    orderByDate(orders, 'date'),
                    currentPage
                  ).map((order, i) => (
                    <tr key={i}>
                      <td>
                        {order.internal ? (
                          <a
                            href={`../../../../${order.link}`}
                            className='linkHeader'
                          >
                            <FaExternalLinkAlt />
                            {order.vb} - PDF
                          </a>
                        ) : (
                          <a href={order.link} className='linkHeader'>
                            <FaExternalLinkAlt />
                            {order.vb}
                          </a>
                        )}
                      </td>
                      <td>
                        {order.order}
                        {order.note && (
                          <b>
                            <br />
                            {order.note}
                          </b>
                        )}
                      </td>
                      <td>{order.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {orders.length > 10 && (
              <PaginationNav
                currentPage={currentPage}
                clickEvt={setPage}
                resultsLength={orders.length}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ChargesPage
